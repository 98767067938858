<template>
    <v-container class="primary-font f20 fw600 d-flex flex-column mb-10 mt-3">
        <v-row no-gutters class="mb-3">
            <v-col cols="12" md="8">
                <div class="f16">Course Offerings</div>
                <div>
                    <span class="l-primary--text">Free!&nbsp;</span> Trainings
                </div>
            </v-col>
            <v-spacer />
            <v-col cols="12" md="3" v-if="!$vuetify.breakpoint.mobile">
                <v-text-field outlined dense class="float-right" solo color="l-primary" flat placeholder="Search courses" v-model="search" hide-details prepend-inner-icon="mdi-magnify"/>
            </v-col>
        </v-row>
        <v-divider class="l-primary" width="50%" />
        <div v-if="free_courses.length === 0" class="fw600 f14 secondary--text my-3"><i>No Courses Available</i></div>
        <!-- <section class="d-flex flex-wrap my-10" :class="$vuetify.breakpoint.smAndDown && 'justify-center'">
            <galleryCard v-for="(course, i) in filtered" :key="i" :course="course" class="ma-1"/>
        </section> -->

        <section v-if="filtered.length>0" class="d-flex my-10" :class="[$vuetify.breakpoint.mobile ? 'flex-column justify-center' : 'flex-wrap']">
            <galleryCard v-for="(course, i) in filtered" :key="i" :course="course" class="ma-1" v-if="!$vuetify.breakpoint.mobile"/>
            <listCard v-for="(course, i) in filtered" :key="i" :course="course" class="ma-3" v-if="$vuetify.breakpoint.mobile"/>
        </section>
    </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import galleryCard from "@/components/landing/cards/gallery-card.vue";
import listCard from "@/components/landing/cards/list-card.vue"

export default {
    components: {
        galleryCard,
        listCard
    },
    mounted() {
        this.getTenantCoursesAction({ is_free: true})
    },
    data: () => ({
        search: ''
    }),
    computed: {
        ...mapState({
            free_courses: (state) => state.free_courses,
        }),

        filtered() {
            if(this.search.length > 0) {
                return this.free_courses.filter(item => item.title.toLowerCase().includes(this.search.toLowerCase()))
            } else {
                return this.free_courses
            }
        }
    },
    methods: {
        ...mapActions(['getTenantCoursesAction'])
    }
}
</script>